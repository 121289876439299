




















































import userService from '@/shared/services/userService'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import { debounceInput } from '@/shared/helpers'

export default Vue.extend({
  name: 'ForumTable',

  props: {
    selectedVehicle: { type: String, default: '' },
  },
  data() {
    return {
      topics: [] as any[],
      debounce: undefined as number | undefined,
      total: 0,
      options: { itemsPerPage: 5 } as any,

      headers: [
        {
          text: this.$t('created'),
          value: 'createdAt',
        },
        {
          text: this.$t('title'),
          value: 'title',
        },
        {
          text: this.$t('vehicle'),
          value: 'vehicle.name',
        },
        {
          sortable: false,
          text: this.$t('actions'),
          value: 'actions',
          align: 'end',
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      account: 'account',
      company: 'company',
    }),
    userIsSystemAdmin() {
      return userService.userIsSystemAdmin()
    },
  },
  async mounted() {
    this.getTopics()
  },
  watch: {
    selectedVehicle: {
      async handler() {
        this.getTopics()
        this.options.page = 1
      },
    },
    search: {
      async handler() {
        this.debounce = debounceInput(this.debounce, this.getTopics)
        this.options.page = 1
      },
    },
    options: {
      handler() {
        this.getTopics()
      },
    },
  },
  methods: {
    userCanDeleteTopic(userIdThatCreatedTopic: string) {
      return this.userIsSystemAdmin || userIdThatCreatedTopic === this.account._id
    },
    async getTopics() {
      const query = {
        l: this.options.itemsPerPage,
        lo: this.options.page - 1,
        sf: this.options.sortBy[0] ? this.options.sortBy[0] : 'createdAt',
        so: this.options.sortDesc[0] ? 'asc' : 'desc',
        vehicle: this.selectedVehicle ? this.selectedVehicle : undefined,
      }
      const result = await this.axios.get('/api/forumTopics', { params: query })
      if (result.status === 200 && result.data) {
        this.topics = result.data.items
        this.total = result.data.total
      }
    },
    async deleteTopic(id: string) {
      this.$confirm(`${this.$t('dialog.are-you-sure')}`, {
        color: 'error',
        buttonTrueText: this.$t('yes').toString(),
        buttonFalseText: this.$t('no').toString(),
        title: this.$t('dialog.delete-topic').toString(),
      }).then(async (res) => {
        if (res) {
          await this.axios.delete('/api/forumTopic/' + id)
          this.getTopics()
        }
      })
    },
  },
})
