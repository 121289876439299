var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"indigo","icon":"mdi-forum","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"mobile-card-heading"},[_vm._v(" "+_vm._s(_vm.$t('topics'))+" ")])]},proxy:true}])},[_c('v-row',[_c('v-col',{staticStyle:{"text-align":"right"}},[_c('v-data-table',{attrs:{"options":_vm.options,"items":_vm.topics,"server-items-length":_vm.total,"headers":_vm.headers},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateForTopics")(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateForTopics")(item.updatedAt))+" ")]}},{key:"item.vehicleId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.vehicle.name)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","to":'/app/forum/' + item._id}},on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.open-topic')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.userCanDeleteTopic(item.user))?_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.deleteTopic(item._id)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.delete-topic')))])])]}}])}),_c('v-btn',{attrs:{"color":"indigo","to":"/app/forum"}},[_vm._v(_vm._s(_vm.$t('view-all')))]),_c('v-btn',{attrs:{"color":"success","to":"/app/forum/new"}},[_vm._v(_vm._s(_vm.$t('create-new-topic')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }