

















































































import { Vehicle } from '@/shared/model/vehicle.model'
import vehicleService from '@/shared/services/vehicleService'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ForumTablePreview from '@/views/app/forum/ForumTablePreview.vue'
import userService from '@/shared/services/userService'

export default Vue.extend({
  name: 'VehicleDetails',

  props: {},
  components: {
    ForumTablePreview,
  },
  data() {
    return {
      vehicle: new Vehicle(),
      vehicleManufacturer: undefined,

      breadcrumbItems: [] as any[],
      canCreateReports: false,
    }
  },
  computed: {
    ...mapGetters({
      account: 'account',
      company: 'company',
    }),
    userIsSystemAdmin() {
      return userService.userIsSystemAdmin()
    },
  },
  watch: {},
  async created() {
    this.updateBreadcrumbs()
    this.canCreateReports = await userService.canCreateReports()
  },
  methods: {
    async updateBreadcrumbs() {
      this.breadcrumbItems = []
      const id = this.$route.params.id

      this.vehicle = await vehicleService.getVehicle(id)
      if (this.vehicle && this.vehicle.manufacturer) {
        this.vehicleManufacturer = await vehicleService.getVehicleManufacturer(this.vehicle.manufacturer)
      }
      if (id && this.vehicle) {
        this.breadcrumbItems = [
          {
            text: this.$t('vehicles'),
            to: '/app/vehicle',
            exact: true,
          },
          {
            text: `${this.vehicle.name}`,
          },
        ]
      }
    },
  },
})
